// i18next-extract-mark-ns-start pbc-ft-formulario-oci

import {Article} from 'components/Article';
import {Button} from 'components/Button';
import {Field, SelectField} from 'components/Field';
import {SEO} from 'components/SEO';
import {Formik, FormikHelpers} from 'formik';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React, {useEffect, useRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';

type FormValues = {
  internal: string;
  details: string;
  recaptchaToken: string;
};

type RequestParams = {
  method?: string;
  headers?: any;
  body?: any;
};
const request = async (url: string, {method = 'GET', headers, body}: RequestParams = {}) => {
  if (method === 'POST') headers = {'Content-Type': 'application/json', ...headers};
  const res = await fetch(url, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined
  });
  const result = await res.json();
  if (!res.ok) throw result || new Error(res.statusText);
  return result;
};

const Page: React.FC = () => {
  const {t} = useI18next();
  const captchaRef = useRef<ReCAPTCHA | null>(null);
  const [isSubmitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (!captchaRef.current) return;
    setTimeout(() => {
      // @ts-ignore
      const container: HTMLDivElement = captchaRef.current.captcha;
      const iframe = container.getElementsByTagName('iframe')[0];
      if (!iframe) return;
      iframe.tabIndex = -1;
    }, 500);
  }, []);

  const handleSubmit = async (
    {internal, details, recaptchaToken}: FormValues,
    {setSubmitting, setErrors}: FormikHelpers<FormValues>
  ) => {
    try {
      await request('https://api.monei.com/report-violation', {
        method: 'POST',
        body: {
          internal: internal === 'true',
          details,
          recaptchaToken
        }
      });
      setSubmitting(false);
      setSubmitted(true);
    } catch (error) {
      setSubmitting(false);
      setErrors({details: error.message});
    }
  };

  const validationSchema = yup.object().shape({
    details: yup.string().required(t('please provide violation details'))
  });

  return (
    <Article style={{minHeight: 1000}}>
      <SEO title={t('Report PBC/FT Regulatory Violation')} />
      <Trans parent="h1" style={{fontSize: 30}}>
        Report PBC/FT Regulatory Violation
      </Trans>
      {isSubmitted ? (
        <Trans parent="p">Your report has been accepted. Thank you!</Trans>
      ) : (
        <Formik
          initialValues={{internal: 'false', details: '', recaptchaToken: ''}}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({isSubmitting, setSubmitting, handleSubmit, setFieldValue}) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!captchaRef.current) return;
                captchaRef.current.execute();
              }}>
              <SelectField name="internal">
                <option value="false">
                  <Trans>PBC/FT Regulatory Violation</Trans>
                </option>
                <option value="true">
                  <Trans>Internal Regulatory Violation PBC/FT MONEI</Trans>
                </option>
              </SelectField>
              <Field as="textarea" name="details" rows="5" placeholder={t('Violation details (If internal, please provide employee name)')} />
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={'6LejBMYUAAAAAIqU0f3BtAMzBqy70HIIbUuKjqvT'}
                tabindex={-1}
                size="invisible"
                onErrored={() => {
                  setSubmitting(false);
                }}
                onExpired={() => {
                  setSubmitting(false);
                }}
                onChange={(captcha) => {
                  setFieldValue('recaptchaToken', captcha);
                  handleSubmit();
                  captchaRef?.current?.reset();
                }}
              />
              <Button type="submit" variant="light" block loading={isSubmitting}>
                <Trans>Submit report</Trans>
              </Button>
            </form>
          )}
        </Formik>
      )}
    </Article>
  );
};

export default Page;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "pbc-ft-formulario-oci"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
